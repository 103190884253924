import { Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WindownActiveService {
  private _windowActiveStatus$ = new BehaviorSubject<boolean>(true);

  get windowActiveStatus$() {
    return this._windowActiveStatus$.asObservable().pipe(
      tap(_windowActiveStatus => {
        console.log('_windowActiveStatus: ', _windowActiveStatus);
      })
    );
  }

  get windowActiveStatus() {
    return this._windowActiveStatus$.value;
  }

  constructor() {}

  init() {
    try {
      if (!this.isLocalhost()) {
        this._listenVisibleTab();
      }
    } catch (error) {
      console.log('🚀 ~ error', error);
    }
  }

  private _listenVisibleTab() {
    let stateKey, eventKey;
    const keys = {
      hidden: 'visibilitychange',
      webkitHidden: 'webkitvisibilitychange',
      mozHidden: 'mozvisibilitychange',
      msHidden: 'msvisibilitychange'
    };
    for (stateKey in keys) {
      if (stateKey in window.parent.document) {
        eventKey = keys[stateKey];
        break;
      }
    }

    if (stateKey) {
      this._windowActiveStatus$.next(!document[stateKey]);
    }

    if (eventKey) {
      window.parent.document.addEventListener(eventKey, () => {
        this._windowActiveStatus$.next(!document[stateKey]);
      });
    }
  }

  private isLocalhost() {
    return ['localhost', '127.0.0.1'].indexOf(window.location.hostname) > -1;
  }
}
