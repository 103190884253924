import { NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { parse } from 'date-fns';
import { takeUntil } from 'rxjs';
import { distinctUntilChanged, startWith, tap } from 'rxjs/operators';
import { DestroySubscriberComponent } from '../destroy-subscriber.component';

@Component({
  selector: 'shc-reactive-input-timepicker',
  templateUrl: './reactive-input-timepicker.component.html',
  styleUrls: ['./reactive-input-timepicker.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    NgxMatTimepickerModule,
    MatButtonModule,
    MatMenuModule
  ]
})
export class ReactiveInputTimepickerComponent extends DestroySubscriberComponent implements OnInit {
  @Input() placeholder = 'Time';
  @Input() showSpinners = true;
  @Input() showSeconds = false;
  @Input() stepHour = 1;
  @Input() stepMinute = 1;
  @Input() stepSecond = 1;
  @Input() timeControl: FormControl<string>;
  @Input() required: boolean;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  time: Date = parse(this.showSeconds ? '00:00:00' : '00:00', 'HH:mm', new Date());
  displayedTime: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.timeControl.valueChanges
      .pipe(
        takeUntil(this.destroySubscriber$),
        startWith(this.timeControl.valueChanges),
        distinctUntilChanged(),
        tap(_ => {
          this._setDisplayedTime();
          this._setTime();
        })
      )
      .subscribe();
  }

  onClosedMenu() {
    this._setTime();
  }

  onSaveTime() {
    this.timeControl.setValue(this.time.toISOString());
    this.trigger.closeMenu();
  }

  private _setDisplayedTime() {
    const isoDateString = this.timeControl.value;

    if (isoDateString) {
      const date = new Date(isoDateString);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const formattedTime = `${hours}:${minutes}`;

      this.displayedTime = formattedTime;
    } else {
      this.displayedTime = this.showSeconds ? '00:00:00' : '00:00';
    }
  }

  private _setTime() {
    this.time = this.timeControl.value
      ? new Date(this.timeControl.value)
      : parse(this.showSeconds ? '00:00:00' : '00:00', 'HH:mm', new Date());
  }
}
