import { WalletOrg } from '@b3networks/api/billing';
import { DEFAULT_ORG_LOGO } from '@b3networks/shared/common';

export class EmailInfo {
  email: string;
  token: string;
}

export class UnverifiedEmails {
  email: string;
  token: string;
  createdDateTime: string;
  lastTriggeredDateTime: string;
  expiryDateTime: string;
}

export class BillingInfo {
  billingName?: string;
  name?: string;
  emails?: string[];
  unverifiedEmails?: UnverifiedEmails[];
  addressLineOne?: string;
  addressLineTwo?: string;
  city?: string;
  countryCode?: string;
  zip?: string;
  state?: string;
  receiveInvoice?: boolean;
  brandingCustomer?: boolean;
  billingEmail: string; // use to add email

  constructor(obj?: Partial<BillingInfo>) {
    if (obj) {
      Object.assign(this, obj);
      if ('unverifiedEmails' in obj) {
        this.unverifiedEmails = obj['unverifiedEmails'];
      }
    }
  }
}
export class CustomerOrganization {
  customerId?: string;
  entityId: number;
  name: string;
  uuid?: string;
}

export class ContactCustomerId {
  type: string;
  val: string;
  contactId?: string;
}

export class Organization {
  uuid: string;
  orgUuid: string;
  name: string;
  shortName: string;
  logoUrl: string;
  walletUuid: string;
  walletCurrencyCode: string;
  wallets?: WalletOrg[];
  currencyCode: string;
  createdDateTime: number;
  domain: string;
  timezone: string;
  countryCode: string;
  status: string;
  integrationCode: string;
  timeFormat: string;
  billingInfo: BillingInfo;
  isPartner: boolean;
  licenseEnabled: boolean;
  msTeamsTenant: MsTeamsTenant;
  type: 'ADMIN' | 'DEMO' | 'CUSTOMER';
  favorite?: boolean;
  role?: string;
  moviusOrgId?: string;
  accountType?: string;
  //ui
  isFlag?: boolean;

  constructor(obj?: Partial<Organization>) {
    if (obj) {
      Object.assign(this, obj);
      if ('billingInfo' in obj) {
        this.billingInfo = new BillingInfo(obj['billingInfo']);
      }
      if (!this.logoUrl) {
        this.logoUrl = DEFAULT_ORG_LOGO;
      }
      this.wallets =
        obj['wallets'] != null && obj['wallets'].length
          ? obj['wallets'].map(x => Object.assign(new WalletOrg(), x))
          : [WalletOrg.fakeWallet(obj['walletCurrencyCode'])];
    }
  }

  get utcOffset(): string {
    return this.timezone ? this.timezone.substring(3, 8) : '+0800';
  }

  get isSuspendedOrg(): boolean {
    return this?.status === ORGANIZATION_STATUS.suspended || this?.status === ORGANIZATION_STATUS.SuspendedForDeletion;
  }
}

export class CreateOrgRequest {
  name: string;
  domain?: string; // Only need for hoiio domains
  shortName: string;
  domainKey: string;
  billingEmail: string;
  logoUrl?: string;
  owner: Owner;
  countryCode: string;
  currencyCode: string;
  timezoneUuid: string;
  licenseEnabled: boolean;
  msTeamsTenantId?: string;
  moviusOrgId?: string;
  customerRef?: string;
  billingRef?: string;
  typeRef?: string;
}

export interface OrgCustomer {
  entityId?: number;
  customerId?: string;
  name: string;
  defaultCurrency: string;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  state: string;
  zip: string;
  countryCode: string;
}

export class Owner {
  uuid?: string; // if available then ignore below fields
  domain: string;
  givenName: string;
  familyName: string;
  email: string;
  timezone: string;
}

export class CreateCompanyRequestV2 {
  constructor(
    public name: string,
    public shortName: string,
    public billingEmail: string,
    public description: string,
    public logoUrl: string,
    public timezoneUuid: string,
    public countryCode: string
  ) {}
}

export class CreateCompanyV2Response {
  public uuid: string;
}

export class CreateCompanyFormRequest {
  constructor(public name: string, public country: string, public timezone: string) {}
}

export interface ChangeCompanyRequest {
  newName: string;
  additionalComments: string;
  orgUuid: string;
}

export interface UpdateCompanyRequest {
  billingInfo?: BillingInfo;
  logoUrl?: string;
  name?: string;
  shortName?: string;
  timezoneUuid?: string;
  orgUuid?: string;
  timeFormat?: string;
  description?: string;
  status?: string;
  integrationCode?: string;
  cancelDeletionRequest?: boolean;
}

export class OrgRole {
  currentRole: string;
  availableRoles: string[];

  constructor(obj?: Partial<OrgRole>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }

  get isAdmin() {
    return (
      this.currentRole.toLowerCase() === 'owner' ||
      this.currentRole.toLowerCase() === 'super_admin' ||
      this.currentRole.toLowerCase() === 'admin'
    );
  }
}

export interface MemberCount {
  count: number;
}

export class PinPolicy {
  inactivityTimeoutMinutes: number;

  constructor(obj?: Partial<PinPolicy>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export interface FindOrganizationReq {
  name?: string;
}

export interface QueryOrgPolicy {
  service: string;
  action: string;
}

export interface QueryOrgReq {
  keyword?: string;
  licenseEnabled?: boolean;
}

export interface SetDemoReq {
  demo: boolean;
}

export interface MsTeamsTenant {
  tenantId: string;
}

export interface CheckOrganizationResponse {
  name: string;
  timezone?: string;
  domain: string;
  portalDomain: string;
  uuid?: string;
}

export enum PricingModel {
  old = 'App type (SIP, Virtual Line, Direct Line, Bizphone...)',
  new = 'License type'
}

export enum SubscriptionModel {
  license = 'license',
  app = 'app'
}

export enum ORGANIZATION_STATUS {
  active = 'ACTIVE',
  suspended = 'SUSPENDED',
  SuspendedForDeletion = 'Suspended for deletion'
}
