import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router, RouterModule } from '@angular/router';
import {
  ActionTxn,
  Notification,
  NotificationType,
  NotificationsQuery,
  NotificationsService,
  TxnChannel,
  TxnService
} from '@b3networks/api/inbox';
import { TxnTransferTransactionComponent, TxnTransferTransactionData } from '@b3networks/chat/shared/txns';
import { SYSTEM_IDENTITY } from '@b3networks/shared/common';
import { ButtonLoadingModule } from '@b3networks/shared/ui/material';
import { ToastAnimationState, ToastService, toastAnimations } from '@b3networks/shared/ui/toast';
import { Observable, filter, finalize, map, of, tap, timer } from 'rxjs';

export interface TxnNotificationModel {
  notification: Notification;
  duration: number;
  classDarkMode?: string;
  removeToast: (id: string) => void;
  navigate: ($event: { notification: Notification; isAccepted: boolean }) => void;
}

@Component({
  selector: 'b3n-txn-notification',
  templateUrl: './txn-notification.component.html',
  styleUrls: ['./txn-notification.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatButtonModule,
    ButtonLoadingModule,
    MatIconModule
  ],
  animations: [toastAnimations.fadeToast]
})
export class TxnNotificationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() notification: Notification;
  @Input() duration: number;
  @Input() classDarkMode: string;

  @Output() removeToast = new EventEmitter<string>(); // notificationId
  @Output() navigate = new EventEmitter<{ notification: Notification; isAccepted: boolean }>(); // notificationId

  loadingType: 'first' | 'secound' = null;
  animationState: ToastAnimationState = 'default';
  countdown$: Observable<number>;

  readonly TxnChannel = TxnChannel;
  readonly NotificationType = NotificationType;
  readonly SYSTEM_IDENTITY = SYSTEM_IDENTITY;

  private _intervalId: any;

  get remainTime() {
    return Math.floor((this.notification?.expiryTime - Date.now()) / 1000);
  }

  constructor(
    private router: Router,
    private txnService: TxnService,
    private notificationsQuery: NotificationsQuery,
    private notificationsService: NotificationsService,
    private toastService: ToastService,
    private dialog: MatDialog,
    public cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['notification'] && this.notification) {
      if ([NotificationType.assigning, NotificationType.transferring].includes(this.notification?.type)) {
        const remainTime = this.remainTime;
        if (remainTime > 0) {
          clearTimeout(this._intervalId);
          this._intervalId = setTimeout(() => this.close(), remainTime * 1000);
        } else {
          this.close();
        }

        this.countdown$ =
          remainTime > 0
            ? timer(0, 1000).pipe(
                map(() => this.remainTime),
                tap(time => time <= 0 && this.close()),
                filter(time => time >= 0)
              )
            : of(null);
      }
    }
  }

  ngOnInit() {
    console.log('ngOnInit: TxnNotificationComponent');
    if (this.duration !== -1) {
      this._intervalId = setTimeout(() => this.close(), this.duration);
    }
  }

  ngOnDestroy() {
    clearTimeout(this._intervalId);
  }

  onFadeFinished(event: any) {
    const { toState } = event;
    const isFadeOut = (toState as ToastAnimationState) === 'closing';
    const itFinished = this.animationState === 'closing';

    if (isFadeOut && itFinished) {
      this.close();
    }
  }

  showDetailTxn() {
    if (this.notification?.txnUuid) {
      if (this.notification?.channel === TxnChannel.call) {
        this.readNotification(this.notification.id + '');
      }

      this.ngZone.run(() => {
        this.navigate.emit({
          notification: this.notification,
          isAccepted: this.notification?.type === NotificationType.accepted
        });
        this.cdr.markForCheck();
      });
    }
  }

  closeNotification() {
    const hasEntity = this.notificationsQuery.hasEntity(this.notification.id);
    if (hasEntity) {
      this.readNotification(this.notification.id + '');
    }
  }

  accept() {
    if (this.notification?.txnUuid) {
      this.loadingType = 'secound';
      const txnUuid = this.notification.txnUuid;
      this.navigate.emit({ notification: this.notification, isAccepted: true });
      this.cdr.markForCheck();
      this.txnService.acceptOrRejectTxn(txnUuid, ActionTxn._acceptAssign, this.notification.id).subscribe(
        () => {
          this.readNotification(this.notification.id + '');
          this.toastService.success('Assigned successfully!');
          this.navigate.emit({ notification: this.notification, isAccepted: true });
          this.cdr.markForCheck();
        },
        err => {
          this.loadingType = null;
          this.toastService.error(err.message);
        }
      );
    }
  }

  reject() {
    if (this.notification?.txnUuid) {
      this.loadingType = 'first';
      this.txnService
        .acceptOrRejectTxn(this.notification.txnUuid, ActionTxn._rejectAssign, this.notification.id)
        .pipe(
          finalize(() => {
            this.loadingType = null;
          })
        )
        .subscribe(
          () => {
            this.toastService.success(`Rejected successfully!`);
          },
          err => {
            this.toastService.error(err.message);
          }
        );
    }
  }

  reassign() {
    this.loadingType = 'secound';
    const dialog = this.dialog.open(TxnTransferTransactionComponent, {
      width: '500px',
      data: <TxnTransferTransactionData>{
        txnUuid: this.notification?.txnUuid,
        channel: this.notification?.channel,
        onlyAgent: false
      },
      disableClose: true,
      panelClass: this.classDarkMode
    });

    dialog.afterClosed().subscribe(isSubmit => {
      this.loadingType = null;
      if (isSubmit) {
        this.readNotification(this.notification.id + '');
      }
      this.cdr.markForCheck();
    });
  }

  cancelTransfer() {
    if (this.notification?.txnUuid) {
      this.loadingType = 'first';
      this.txnService
        .acceptOrRejectTxn(this.notification.txnUuid, ActionTxn._rejectAssign, this.notification.id)
        .pipe(
          finalize(() => {
            this.loadingType = null;
          })
        )
        .subscribe(
          () => {
            this.toastService.success(`Canceled successfully!`);
          },
          err => {
            this.toastService.error(err.message);
          }
        );
    }
  }

  private close() {
    this.removeToast.emit(this.notification.id + '');
  }

  private readNotification(notificationId: string) {
    this.notificationsService
      .notificationClicked(false, [+notificationId], Date.now())
      .pipe(
        finalize(() => {
          this.loadingType = null;
        })
      )
      .subscribe(() => {
        this.removeToast.emit(notificationId);
      });
  }
}
