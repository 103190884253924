import { SalesModel } from '@b3networks/api/salemodel';

const subscriptionCycle = {
  monthly: 'monthly',
  yearly: 'yearly'
};

export class Sku {
  sku: string;
  name: string;
  description: string;
  isPrimary: boolean;
  order: number;
  product_id?: string;
  features: string[];
  price: number;
  status?: 'PUBLISHED' | string;
  code?: string;
  salesModels?: SalesModel[] = [];

  static buildFromReponse(params: any): Sku {
    return new Sku({ code: params['sku'], name: params['name'], isPrimary: params['isPrimary'] });
  }

  static buildFromSalesModelResponse(params: any) {
    let salesModels: SalesModel[] = [];
    if (params['saleModel'] && params['saleModel'].length > 0) {
      salesModels = [];
      params['saleModel'].forEach(element => {
        const sm = SalesModel.buildFromResponse(element);
        if (sm != null) {
          salesModels.push(sm);
        }
      });
    }

    return salesModels.length > 0
      ? new Sku({ code: params['skuCode'], name: params['name'], isPrimary: null, salesModels })
      : null;
  }

  constructor(obj?: any) {
    Object.assign(this, obj);
    this.features = this.extractFeatures();
  }

  getTotalCount(code: string) {
    if (this.salesModels.length === 0) {
      return 0;
    }
    return this.salesModels
      .filter(s => s.code === code)
      .map(s => s.amount)
      .reduce((a, b) => {
        return a + b;
      }, 0);
  }

  get subscriptionLestedCycleSupport() {
    if (!this.salesModels) {
      return null;
    }
    const salesModelCodes = this.salesModels.map(sm => sm.code);
    return salesModelCodes.indexOf(subscriptionCycle.monthly) !== -1
      ? subscriptionCycle.monthly
      : salesModelCodes.indexOf(subscriptionCycle.yearly)
      ? subscriptionCycle.yearly
      : null;
  }

  get isPublished() {
    return this.status === 'PUBLISHED';
  }

  getFlagClass(): string {
    return `${this.sku.substr(0, 2).toLowerCase()} flag`;
  }

  extractFeatures(): string[] {
    const features = [];
    const featureParts: string[] = !this.description ? [] : this.description.split(/\n/gi);

    featureParts.forEach(feature => {
      if (feature.startsWith('- :feature: ')) {
        features.push(feature.replace('- :feature: ', ''));
      }
    });

    return features;
  }
}

export interface GetSkuReq {
  productId: string;
  filter?: string;
}

export interface CreateProductSku {
  sku: string;
  name: string;
  status: 'PUBLISHED' | string;
  isPrimary: boolean;
  order: number;
  description?: string;
}

export class StoreProduct {
  public productId: string;
  public name: string;
  public shortDescription: string;
  public logo: string;
  public type: string;

  constructor(value?: any) {
    if (!!value) {
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          this[key] = value[key];
        }
      }
    }
  }

  isTypeNumber() {
    return this.type === 'NUMBER';
  }

  isTypeHardware() {
    return this.type === 'HARDWARE';
  }

  isTypeService() {
    return this.type === 'SERVICE';
  }

  isTypeConnector() {
    return this.type === 'CONNECTOR';
  }
}

export class ExtendedStoreProduct extends StoreProduct {
  public isProductOwner: boolean;
  public upstreamSellerName: string;
  public upstreamSellerUuid: string;

  constructor(value?: any) {
    super(value);
    if (!!value) {
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          this[key] = value[key];
        }
      }
    }
  }
}

export class StoreChannel {
  public partnerUuid: string;
  public partnerName: string;
  public domain: string;
  public defaultCurrency: string;

  constructor(value: Object) {
    if (!!value) {
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          this[key] = value[key];
        }
      }
    }
  }
}

export class StoreSku {
  public sku: string;
  public name: string;
  public description: string;
  public order: number;
  public isPrimary: boolean;

  constructor(value?: any) {
    if (!!value) {
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          this[key] = value[key];
        }
      }
    }
  }
}

export class StoreVisibility {
  public excludedDomains: string[];
  public includedDomains: string[];
  public type: string;

  constructor(value?: any) {
    if (!!value) {
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          this[key] = value[key];
        }
      }
    }
  }
}

export class ItemCode {
  id: number;
  orgUuid: string;
  code: string;

  constructor(obj?: Partial<ItemCode>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export class AppByFeatureResponse {
  id: string;
}

export interface Visibility {
  excludedDomains: string[];
  includedDomains: string[];
  type: 'NONE' | 'ALL';
}

export interface ProductVisibility {
  productVisibility: Visibility;
  skuVisibilities: {
    [sku: string]: Visibility;
  };
}

export class DistributingSku {
  sku: string;
  name: string;
  description: string;
  order: number;
  inheritVisibility?: boolean;
  visibility?: Visibility;
  isPrimary: boolean;
  selling?: boolean;
  lastUpdatedDate?: string;
  status?: string;
  saleModels?: SalesModel[];

  // UI
  isAvailable: boolean;

  constructor(obj?: Partial<DistributingSku>) {
    Object.assign(this, obj);
  }
}

export interface DistributingSkuReq {
  channelOrgUuid: string;
  productId: string;
  skus: DistributingSkuOption[];
}

export interface DistributingSkuOption {
  sku: string;
  show: boolean;
}

export class SkuModel {
  sku: string;
  name: string;
  requireDoc?: boolean;
}
