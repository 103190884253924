import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { CallHistoryFilter, SmsHistoryFilter, SupportHubCallHistory } from './support-hub.model';

export interface SupportHubState {
  callFilter: CallHistoryFilter;
  smsFilter: SmsHistoryFilter;
  callHistorySelected: SupportHubCallHistory;
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'support-hub' })
export class SupportHubStore extends Store<SupportHubState> {
  constructor() {
    super({ callFilter: null, smsFilter: null, callHistorySelected: null });
  }
}
