import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ConnectedOrgInfo } from './connected-org.model';

export interface ConnectedOrgsState extends EntityState<ConnectedOrgInfo> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox_connect_org', idKey: 'inboxUuid' })
export class ConnectedOrgsStore extends EntityStore<ConnectedOrgsState> {
  constructor() {
    super();
  }
}
