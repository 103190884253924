import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pageable } from '@b3networks/api/common';
import { CaseDetail, CaseIdentity, StoreCaseReq, UpdateDraftCaseReq } from '@b3networks/api/workspace';
import { map, tap } from 'rxjs';
import { CaseRelatedTo, QueryCaseRelatedTo, RelatesTo, ReqCasesRelatedTo } from './cases.model';
import { CasesQuery } from './cases.query';
import { CasesStore } from './cases.store';

@Injectable({
  providedIn: 'root'
})
export class CasesService {
  constructor(private http: HttpClient, private store: CasesStore, private query: CasesQuery) {}

  // TODO: move or rename service to relatedTo service
  getRelatedCasesByTxn(orgUuid: string, txnUuid: string) {
    return this.http.get<CaseRelatedTo[]>(`inbox/private/v2/txn/${txnUuid}/relatedTos`).pipe(
      map(list => list?.map(x => new CaseRelatedTo(x)) || []),
      map(
        list =>
          new RelatesTo({
            fromTxn: txnUuid,
            orgUuid: orgUuid,
            listRelatedTo: list
          })
      ),
      tap(item => {
        this.store.upsert(item.key, item);
      })
    );
  }

  // TODO: move or rename service to relatedTo service
  updateRelatedCases(
    orgUuid: string,
    txnUuid: string,
    req: {
      relatedInfo: CaseRelatedTo[];
      action: 'remove' | 'add';
    }
  ) {
    return this.http.put<CaseRelatedTo[]>(`inbox/private/v2/txn/${txnUuid}/relatedTos`, req).pipe(
      map(list => list?.map(x => new CaseRelatedTo(x)) || []),
      tap(item => {
        const key = `${orgUuid}_${txnUuid}`;
        this.store.upsert(key, <RelatesTo>{
          listRelatedTo: item
        });
      })
    );
  }

  createCaseDraftV2(data: StoreCaseReq) {
    return this.http.post<CaseDetail>(`/inbox/private/v4/cases/draftV2`, data);
  }

  updateCaseDraftV2(identifier: CaseIdentity, data: Partial<UpdateDraftCaseReq>) {
    return this.http
      .put<CaseDetail>(`/inbox/private/v4/cases/draftV2/${identifier.ownerOrgUuid}/${identifier.sid}`, data)
      .pipe(map(c => new CaseDetail(c)));
  }

  queryCases(req: ReqCasesRelatedTo, pageable?: Pageable) {
    let params = new HttpParams();
    if (pageable) {
      params = params.set('page', String(Number(pageable.page))).set('perPage', String(pageable.perPage));
    }
    return this.http
      .post<QueryCaseRelatedTo[]>(`/inbox/private/v2/txn/relatedTos/_query`, req, { params: params })
      .pipe();
  }
}
