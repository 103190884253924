import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Idle, NotIdle } from 'idlejs/dist';
import { CHAT_PUBLIC_PREFIX } from '../constants/constants';

export enum WsState {
  active = 'active',
  inactive = 'inactive'
}

@Injectable({ providedIn: 'root' })
export class IdleService {
  private idle: Idle;
  private notIdle: NotIdle;

  idleStatus: WsState = WsState.active;

  constructor(private http: HttpClient, private ngZone: NgZone) {}

  start() {
    this.stop();
    console.log('started idle...');
    this.idle = new Idle();
    this.notIdle = new NotIdle();
    this.idleStatus = WsState.active;

    this.ngZone.runOutsideAngular(() => {
      // if user idle in document 2 minutes ==> send idle status into ms-chat
      this.idle
        .whenNotInteractive()
        .within(2)
        .do(() => this.updateWebsocketStatus(WsState.inactive))
        .start();
      // when user interactive --> set active every 5 seconds
      this.notIdle
        .whenInteractive()
        .within(5, 1000)
        .do(() => this.updateWebsocketStatus(WsState.active))
        .start();
    });
  }

  stop() {
    this.ngZone.runOutsideAngular(() => {
      console.log('stopped idle...');
      this.idle?.stop();
      this.notIdle?.stop();
    });
  }

  private updateWebsocketStatus(state: WsState) {
    if (state === this.idleStatus) {
      return;
    }

    console.log('idle state: ', state);
    this.http
      .post<{ state: WsState }>(`${CHAT_PUBLIC_PREFIX}/wss/status`, { state: state })
      .subscribe((x: { state: WsState }) => {
        this.idleStatus = x.state;
      });
  }
}
