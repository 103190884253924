import { KeyValue } from '@angular/common';
import { ElementRef } from '@angular/core';
import { Channel, ChannelHyperspace, ChatMessage, ReplyMessage } from '@b3networks/api/chat';
import { ChannelStateV3, ChannelStateV4, Contact } from '@b3networks/api/contact';
import { Inbox, TxnChannel, TxnStatus, ViewMode } from '@b3networks/api/inbox';
import { User, UserHyperspace } from '@b3networks/api/workspace';

export interface AppState {
  showMainSidebar: boolean;
  sidebarTabActive?: SidebarTabs;
  emailUWState?: EmailUWState;
  endTxnsUWState?: EndTxnsUWState;
  endTxnsUWOrgState?: EndTxnsUWState;

  showRightSidebar: boolean;
  showLeftSidebar: boolean;
  modeLeftSidebar: ModeSidebar;
  modeRightSidebar: ModeSidebar;
  isLeftChatSidebar: boolean;

  mentionCountTeamChat: number;
  quillEditor: {
    triggerfocus: boolean;
  };
  triggerScrollBottomView: boolean;
  triggerDoCheckScroll: boolean;

  memberMenu: InfoShowMention;

  popupState: PopupState;
  isCollapseAssignedToMe: boolean;
  isCollapseAllInbox: boolean;
  loadingQueryTxn: boolean;
  initHeightTxn: number;
  viewTxn: ViewTxn;
  triggerRefreshTxn: boolean;
  triggerScrollUpTxn: boolean;
  triggerRefreshQueryTxn: boolean;
  triggerRefreshActiveCall: boolean;
  customerTxnReports: Contact[];

  collapseSidebar: CollapseSidebar;

  triggerIsScrollDownTxn: boolean;

  // TODO: cache with convo;
  triggerSeeAllPinned: boolean;

  submittedTicket: TxnFilterState;
  splitMode: SplitMode;
  isFetchingTxnDetail: boolean; // show loading when switch another txn
  isFetchingQueryTxns: boolean;
  isSmallBreakpont: boolean;
  channelSelected: TxnChannel;

  myWork: TxnFilterStateV1; //view my work
  queryInboxes: TxnFilterStateV1; //view Inboxes
}

export interface CollapseSidebar {
  starred: boolean;
  me: boolean;
  inboxes: boolean;
  watching: boolean;
  related: boolean;
  created: boolean;
  mentions: boolean;
  channel: boolean;
}

export interface RightCLickMessage {
  uuid: string; // identity

  menus: MenuMessageInfo[];
  message: ChatMessage;

  xPosition: number;
  yPosition: number;
  elr: ElementRef;
}

export interface MenuMessageInfo {
  key: MenuMsg;
  showHTML: boolean;
  value: string;
  order: number;
  icon: string;
  size: number; // px
  isSvg: boolean;
  classIcon: string;
  classText: string;

  // input data
  dataReply?: ReplyMessage; // MenuMsg.reply
  targetCopy?: string; // MenuMsg.copyText
  targetLink?: string; // MenuMsg.openTab, MenuMsg.copyLinkAddress

  // MenuMsg.deleteMessage
  deleteMessage?: {
    isPinned: boolean;
  };
}

export interface PopupState {
  isShowPopup: boolean;
  isLoadingTemplate: boolean;
  tag: { [key: string]: string | string[] };
  systemStatusExpiredAt: number;
  remainTime: number;
}

export enum MenuMsg {
  // message
  editMessage = 'editMessage',
  reply = 'reply',
  pinMsg = 'pinMsg',
  unpinMsg = 'unpinMsg',
  createThread = 'createThread',
  copyText = 'copyText',
  copyLinkAddress = 'copyLinkAddress',
  removePreviewLink = 'removePreviewLink',
  deleteMessage = 'deleteMessage',
  jumpFromBookmark = 'jumpFromBookmark',
  removeBookmark = 'removeBookmark',
  addBookmark = 'addBookmark',

  // jumpReply = 'jumpReply',
  // copyLink = 'copyLink',

  // files
  showInChat = 'showInChat',
  downloadFile = 'downloadFile'
}

export interface InfoShowMention {
  xPosition: number;
  yPosition: number;
  member: User | UserHyperspace;
  convo: Channel | ChannelHyperspace;
}

export enum ViewTxn {
  assignToMe = 'assignedToMe', //view Me
  inbox = 'inbox', //view Inbox
  submittedTicket = 'submittedTicket', //view submitted ticket
  report = 'report', //view all transaction
  watching = 'watching', //view submitted ticket
  related = 'related', //view related to me,
  createdByMe = 'createdByMe', //view created by me
  myWork = 'myWork', //view my work
  inboxes = 'inboxes' //view Inboxes
}

export enum ModeSidebar {
  over = 'over',
  side = 'side',
  push = 'push'
}

export enum SidebarTabs {
  teamchat = 'teamchat',
  email = 'email'
}

export enum SplitMode {
  horizontalSplit = 'horizontalSplit', // default
  noSplit = 'noSplit'
}

export interface EmailUWState {
  isExpandPersonal: boolean;
  isExpandTeam: boolean;
  isExpandTeammate: boolean;
}

export interface EndTxnsUWState {
  page?: number;
  perPage?: number;
  hasMore?: boolean;
}

export interface TxnFilterState {
  txnFilter?: ChannelStateV4 | ChannelStateV3;
  txnUuidsFilter?: string[];
}

export interface TxnFilterStateV1 {
  state: ChannelStateV5;
  txnFilter: FilterTxnFormGroupV1;
  txnUuidsFilter: string[];
  loadedFirst?: boolean;
}

export interface ChannelStateV5 {
  loaded?: boolean;
  hasMore?: boolean;
  page: number;
  perPage: number;
}

export const ASSIGNED_OPT_FILTER: KeyValue<TxnStatus, string> = { key: TxnStatus.assigned, value: 'Assigned' };

export interface FilterTxnFormGroupV1 {
  statuses: KeyValue<TxnStatus, string>[];
  inboxUuids: Inbox[];
  channels: ChannelOption;
  viewMode: ViewMode;

  textSearch: string;
  searchBy: SearchBy;

  reporters: AllUsersFilterSuggestion[];
  assignees: AllUsersFilterSuggestion[];
}

export interface FilterTxnFormGroup {
  statuses: KeyValue<TxnStatus, string>[];
  inboxUuids: Inbox[];
  channels: ChannelOption[];
  viewMode: ViewMode;

  textSearch: string;
  searchBy: SearchBy;

  reporters: AllUsersFilterSuggestion[];
  assignees: AllUsersFilterSuggestion[];
}

export interface AllUsersFilterSuggestion {
  uuid: string;
  displayName: string;
  photoUrl: string;
  isUser: boolean;
  isBot: boolean;
  isOrg: boolean;
}

export interface ChannelOption extends KeyValue<TxnChannel, string> {
  isNoSvg?: boolean;
  icon?: string;
}

export const TYPE_LIVECHAT_OPT = {
  key: TxnChannel.livechat,
  value: 'Live chat',
  isNoSvg: false,
  icon: 'b3n_inbox_livechat'
};

// share to set filter for form when init component
export const channelOpts: ChannelOption[] = [
  TYPE_LIVECHAT_OPT,
  { key: TxnChannel.whatsapp, value: 'WhatsApp', isNoSvg: false, icon: 'b3n_inbox_whatsapp' },
  { key: TxnChannel.supportCenter, value: 'Support Ticket', isNoSvg: false, icon: 'b3n_inbox_ticket' },
  { key: TxnChannel.call, value: 'Call', isNoSvg: false, icon: 'b3n_inbox_call' }
];

export interface FetchingSidebar {
  watching: boolean;
  related: boolean;
  created: boolean;
}

export const NameAppStore = 'workspace_app_state';

export enum SearchBy {
  uuid = 'uuid',
  title = 'title',
  sid = 'sid',
  orgUuid = 'orgUuid',
  customer = 'customer'
}
