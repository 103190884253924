import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ConnectedOrgsState, ConnectedOrgsStore } from './connected-org.store';

@Injectable({ providedIn: 'root' })
export class ConnectedOrgsQuery extends QueryEntity<ConnectedOrgsState> {
  constructor(protected override store: ConnectedOrgsStore) {
    super(store);
  }

  selectConnectedOrgsByOrgUuid(connectedOrgUuid: string) {
    return this.selectAll({
      filterBy: entity => entity.connectedOrgUuid === connectedOrgUuid
    });
  }
}
