import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CopyButtonComponent } from './component/copy-button/copy-button.component';
import { ExpiredComponent } from './component/expired/expired.component';
import { InfiniteScrollComponent } from './component/infinite-scroll/infinite-scroll.component';
import { InfinityLoadingComponent } from './component/infinity-loading/infinity-loading.component';
import { InputTimepickerComponent } from './component/input-timepicker/input-timepicker.component';
import { PhoneNumberComponent } from './component/phone-number/phone-number.component';
import { ReactiveInputTimepickerComponent } from './component/reactive-input-timepicker/reactive-input-timepicker.component';
import { ResourceNotFoundComponent } from './component/resource-not-found/resource-not-found.component';
import { SliceTextComponent } from './component/slice-text/slice-text.component';
import { WorkingHourComponent } from './component/working-hour/working-hour.component';
import { ZoomPanContainerComponent } from './component/zoom-pan-container/zoom-pan-container.component';
import { AlphanumbericKeydownDirective } from './directive/alphanumberic-keydown.directive';
import { AutoFocusInputDirective } from './directive/auto-focus-input.directive';
import { InputNumberKeydownDirective } from './directive/input-number-keydown.directive';
import { KeyDownHandlerDirective } from './directive/keydown-handler.directive';
import { LazyImgDirective } from './directive/lazy-img.directive';
import { MaxDateRangeDirective } from './directive/max-date-range.directive';
import { MaxLengthDirective } from './directive/max-length-validator.directive';
import { MinDirective } from './directive/min-validator.directive';
import { PermissibleLimitsDirective } from './directive/permissible-limits-validator.directive';
import { RemoveAriaOwnsDirective } from './directive/remove-aria-owns.directive';
import { ResizableHeightDirective } from './directive/resizable-height.directive';
import { ResizableWidthDirective } from './directive/resizable-width.directive';
import { RetrictInputDirective } from './directive/retrict-input.directive';
import { ScrollEventDirective } from './directive/scroll-event.directive';
import { AbsPipe } from './pipe/abs.pipe';
import { AcronymsCapitalizeCasePipe } from './pipe/acronyms-capitalized-case';
import { CamelTitlePipe } from './pipe/camel-title.pipe';
import { CapitalizeCasePipe } from './pipe/capitalize-case.pipe';
import { ConsentStatusPipe } from './pipe/consent-status.pipe';
import { CreditPipe } from './pipe/credit.pipe';
import { DisplayTextPipe } from './pipe/display-text.pipe';
import { DistanceToNowPipe } from './pipe/distance-now.pipe';
import { DNCStatusPipe } from './pipe/dnc-status.pipe';
import { DurationV2Pipe } from './pipe/duration-v2.pipe';
import { DurationV3Pipe } from './pipe/duration-v3.pipe';
import { DurationPipe } from './pipe/duration.pipe';
import { ExtDevicePipe } from './pipe/ext-device.pipe';
import { FileSizePipe } from './pipe/file-size.pipe';
import { FilterByProductNamePipe } from './pipe/filter-by-product-name.pipe';
import { FirstWordPipe } from './pipe/first-word.pipe';
import { HighlightPipe } from './pipe/highlight.pipe';
import { IdentityUuidNamePipe } from './pipe/identity-uuid-name.pipe';
import { InstanceofPipe } from './pipe/instance-of.pipe';
import { IsUuidPipe } from './pipe/is-uuid.pipe';
import { LinkifyPipe } from './pipe/linkify.pipe';
import { ListFilterPipe } from './pipe/list-filter.pipe';
import { MatchProductTypePipe } from './pipe/match-product-type.pipe';
import { Nl2pPipe } from './pipe/nl2p.pipe';
import { ReplacePipe } from './pipe/replace';
import { ReplaceSpecialCharacterPipe } from './pipe/replace-special-character.pipe';
import { RingConfigPipe } from './pipe/ring-config.pipe';
import { SafePipe } from './pipe/safe.pipe';
import { SentenceCasePipe } from './pipe/sentence-case.pipe';
import { SortListPipe } from './pipe/sort-list.pipe';
import { UserOnlinePipe } from './pipe/status-user.pipe';
import { SplitTextPipe } from './pipe/text-split.pipe';
import { TimeAgoPipe } from './pipe/time-ago.pipe';
import { TimeDurationPipe } from './pipe/time-duration.pipe';
import { TodayPipe } from './pipe/today.pipe';
import { TransferCallerIDPipe } from './pipe/transfer-callerid.pipe';
import { TruncatePipe } from './pipe/truncate.pipe';
import { TypeTxnPipe } from './pipe/type-txn.pipe';
import { ViewDatePipe } from './pipe/view-date.pipe';

const PIPES = [
  DurationPipe,
  TimeDurationPipe,
  TruncatePipe,
  TransferCallerIDPipe,
  TimeAgoPipe,
  SplitTextPipe,
  CamelTitlePipe,
  SafePipe,
  FirstWordPipe,
  ReplaceSpecialCharacterPipe,
  ListFilterPipe,
  HighlightPipe,
  SentenceCasePipe,
  ExtDevicePipe,
  ViewDatePipe,
  CapitalizeCasePipe,
  TodayPipe,
  ReplacePipe,
  DistanceToNowPipe,
  FileSizePipe,
  DNCStatusPipe,
  ConsentStatusPipe,
  CreditPipe,
  AbsPipe,
  MatchProductTypePipe,
  FilterByProductNamePipe,
  Nl2pPipe,
  InstanceofPipe,
  LinkifyPipe,
  DurationV2Pipe,
  IdentityUuidNamePipe,
  UserOnlinePipe,
  DurationV3Pipe,
  AcronymsCapitalizeCasePipe,
  TypeTxnPipe,
  RingConfigPipe,
  DisplayTextPipe,
  IsUuidPipe,
  SortListPipe
];

const DIRECTIVES = [
  ScrollEventDirective,
  RetrictInputDirective,
  LazyImgDirective,
  MinDirective,
  InputNumberKeydownDirective,
  AlphanumbericKeydownDirective,
  MaxLengthDirective,
  PermissibleLimitsDirective,
  AutoFocusInputDirective,
  ResizableHeightDirective,
  ResizableWidthDirective,
  KeyDownHandlerDirective,
  RemoveAriaOwnsDirective,
  MaxDateRangeDirective
];

const COMPONENTS = [
  InfiniteScrollComponent,
  PhoneNumberComponent,
  CopyButtonComponent,
  ResourceNotFoundComponent,
  SliceTextComponent,
  InputTimepickerComponent,
  WorkingHourComponent,
  ReactiveInputTimepickerComponent,
  ZoomPanContainerComponent,
  InfinityLoadingComponent,
  ExpiredComponent,
  SliceTextComponent
];

@NgModule({
  imports: [
    CommonModule,

    // standalone
    DIRECTIVES,
    PIPES,
    COMPONENTS
  ],
  declarations: [],
  providers: [PIPES],
  exports: [PIPES, DIRECTIVES, COMPONENTS, FormsModule, ReactiveFormsModule]
})
export class SharedCommonModule {}
