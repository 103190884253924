import { Injectable } from '@angular/core';
import { IdentityProfileQuery } from '@b3networks/api/auth';
import { AudioPlayerService, TypeSound } from '@b3networks/api/call';
import {
  Channel,
  ChatMessage,
  ConvoType,
  MsgType,
  SystemMessageData,
  SystemMsgType,
  UserType
} from '@b3networks/api/chat';
import { Contact, ContactQuery } from '@b3networks/api/contact';
import { Txn, TxnQuery, TxnService } from '@b3networks/api/inbox';
import {
  ConversationGroup,
  ConversationGroupService,
  MeQuery,
  User,
  UserQuery,
  UserService
} from '@b3networks/api/workspace';
import { Match, MatchType, RegExpPattern, WindownActiveService, randomGuid } from '@b3networks/shared/common';
import { BrowserNotificationService, NotificationEvent, NotificationRouterLink } from '@b3networks/shared/notification';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ConvoHelperService, SupportedConvo } from '../adapter/convo-helper.service';
import { MessageConstants } from '../constant/message.const';
import { SUPPORTED_CHANNEL } from './message-receive.processor';

@Injectable({ providedIn: 'root' })
export class MessageNotificationProcessor {
  notNotification: boolean;

  constructor(
    private meQuery: MeQuery,
    private browserNotification: BrowserNotificationService,
    private contactQuery: ContactQuery,
    private windownActiveService: WindownActiveService,
    private txnQuery: TxnQuery,
    private txnService: TxnService,
    private userQuery: UserQuery,
    private userService: UserService,
    private convoHelper: ConvoHelperService,
    private audioPlayerService: AudioPlayerService,
    private identityProfileQuery: IdentityProfileQuery,
    private conversationGroupService: ConversationGroupService
  ) {}

  showCustomerNotification(message: ChatMessage, convo: SupportedConvo) {
    if (this.notNotification) {
      return;
    }

    // convo can be null
    if (message.mt === MsgType.system) {
      this.handleNotifySystem(message, convo);
      return;
    }

    if (this.meQuery.getMe()?.userUuid === message.user) {
      return;
    }

    if (this.windownActiveService.windowActiveStatus) {
      const activeId = this.convoHelper.getActiveByChannel(convo);
      if (activeId) {
        const id = convo instanceof ConversationGroup ? convo?.conversationGroupId : convo?.id;
        if (activeId === id) {
          return;
        }
      }
    }

    let content = '';
    if ([MsgType.attachment, MsgType.prechatsurvey].indexOf(message.mt) > -1) {
      content = message.body?.data?.name;
    } else {
      content = this.getMessageNotificationContent(message);
    }

    if ([ConvoType.whatsapp, ConvoType.LIVECHAT].includes(message.ct) && message.ut === UserType.Customer) {
      const contact = this.contactQuery.getContactByChatCustomerId(message.user);
      this.fireNotify(message, convo, contact?.displayName, content);
    } else {
      of(this.userQuery.getUserByChatUuid(message.user))
        .pipe(
          mergeMap(users =>
            users != null
              ? of([users])
              : this.userService
                  .findOneByUuidAndUserTypeSequentially(message.user, 'chatId')
                  .pipe(map(x => (x ? [x] : [])))
          )
        )
        .subscribe(users => {
          let userName = 'New customer';
          if (users?.[0]) {
            userName = users[0].displayName;
          } else {
            if (convo && convo instanceof ConversationGroup) {
              userName = convo?.customerInfo?.name || convo?.customerInfo?.email;
            }
          }

          this.fireNotify(message, convo, userName, content);
        });
    }
  }

  private fireNotify(message: ChatMessage, convo: SupportedConvo, displayName: string, content: string) {
    let title = 'New message';
    let contentFinnal = content;
    let findContact: Contact;
    const txn: Txn = this.txnQuery.findTxnByConvo(message.convo);

    if (SUPPORTED_CHANNEL.indexOf(message.ct) > -1) {
      if (message.mt === MsgType.attachment) {
        title = MessageConstants.NOTIFY_NEW_ATTACHMENT(displayName);
      } else if (message.mt === MsgType.email) {
        title = MessageConstants.NOTIFY_NEW_EMAIL(displayName);
      } else {
        if ((convo as Channel).isGroupChat) {
          title = MessageConstants.NOTIFY_NEW_MSG_IN_CHANNEL(convo?.displayName);
          contentFinnal = `${displayName}: ${content}`;
        } else {
          title = MessageConstants.NOTIFY_NEW_MSG(displayName);
        }
      }
    } else if ([ConvoType.whatsapp, ConvoType.LIVECHAT].indexOf(message.ct) > -1) {
      const me = this.meQuery.getMe();
      if (!txn?.lastAssignedAgents || txn?.lastAssignedAgents.indexOf(me?.identityUuid) === -1) {
        return;
      }

      if (message.mt === MsgType.attachment) {
        title = MessageConstants.NOTIFY_NEW_ATTACHMENT(displayName);
      } else {
        if (displayName) {
          contentFinnal = `${displayName}: ${content}`;
        }

        if (message.ut === UserType.Customer) {
          title = MessageConstants.NOTIFY_NEW_MSG_IN_CONTACT(displayName);
        } else {
          findContact = this.contactQuery.getEntity(txn?.customerUuid);
          if (findContact) {
            title = MessageConstants.NOTIFY_NEW_MSG_IN_CONTACT(findContact?.displayName);
          } else {
            title = 'New message';
          }
        }
      }
    } else if ([ConvoType.INTERNAL_SPACE].includes(message.ct)) {
      if (message.mt === MsgType.attachment) {
        title = MessageConstants.NOTIFY_NEW_ATTACHMENT(displayName);
      } else {
        title = MessageConstants.NOTIFY_NEW_MSG_IN_INTERNAL_DISCUSSION();
        contentFinnal = txn || displayName ? '' : content;
        if (txn) {
          contentFinnal += `TXN: ${txn.txnUuid.slice(0, 8)}\n`;
        }

        if (displayName) {
          contentFinnal += `${displayName}: ${content}`;
        }
      }
    } else if (ConvoType.email === message.ct) {
      return;
    }

    const routerLink = <NotificationRouterLink>{
      commands: []
    };
    if (SUPPORTED_CHANNEL.indexOf(message.ct) > -1) {
      routerLink.commands = ['conversations', convo?.id];
    } else if ([ConvoType.whatsapp, ConvoType.LIVECHAT, ConvoType.INTERNAL_SPACE].includes(message.ct)) {
      if (txn?.txnUuid) {
        routerLink.commands = ['txn', txn.txnUuid];
      }
    }

    this.sendNotifyAndSound(title, contentFinnal, routerLink, message.ct, data => {
      if (data.isClicked) {
        if (txn && message.ct === ConvoType.INTERNAL_SPACE) {
          this.txnService.updateTxnViewState(txn.txnUuid, {
            openDiscussion: true
          });
        }
      }
    });
  }

  private sendNotifyAndSound(
    title: string,
    content: string,
    routerLink: NotificationRouterLink,
    convoType: ConvoType,
    cb: (data: NotificationEvent) => void
  ) {
    const needNoti = [ConvoType.LIVECHAT, ConvoType.INTERNAL_SPACE, ConvoType.whatsapp].includes(convoType);

    this.browserNotification
      .sendNotify(
        title,
        <NotificationOptions>{
          body: content,
          icon: this.identityProfileQuery.currentOrg?.photoSrc,
          silent: needNoti
        },
        routerLink
      )
      .subscribe(data => {
        if (data.event?.type === 'show') {
          if (needNoti) {
            this.audioPlayerService.play(TypeSound.notify, false);
          }
        }

        cb(data);
      });
  }

  private getMessageNotificationContent(msg: ChatMessage, conversation?: SupportedConvo, user?: User): string {
    const text =
      msg.mt === MsgType.email
        ? this.getEmailMsgContent(msg)
        : msg.body?.title
          ? msg.body.title
          : msg.body?.text
            ? msg.body.text
            : !msg.body.data
              ? ''
              : msg.body.data?.text
                ? msg.body.data.text
                : '';

    // text = this.htmlToPlaintext(text);
    let newMsg: string = this.preProcessingForNotify(text);

    if (newMsg) {
      // replace show notification has html tags
      newMsg = newMsg.replace(/<(?:.|\n)*?>/gm, '');
      newMsg = _.unescape(newMsg);

      if (conversation && conversation instanceof Channel && user) {
        newMsg = `@${user.displayName}: ${newMsg}`;
      }

      if (newMsg.length > 50) {
        newMsg = newMsg.slice(0, 50) + '...';
      }
    }

    return newMsg;
  }

  private preProcessingForNotify(message: string): string {
    if (!message) {
      return null;
    }

    const matchedArr: Match[] = [];

    let output: string = JSON.parse(JSON.stringify(message));
    const mentionMatched = message.match(RegExpPattern.MENTION);

    if (mentionMatched) {
      mentionMatched.filter(item => {
        const replaceString: string = randomGuid();
        matchedArr.push(new Match(MatchType.MENTION, item, replaceString));
        output = output.replace(item, replaceString);
      });
    }

    matchedArr.filter(item => {
      let replaceItem: string = item.matched;

      if (item.type === MatchType.MENTION) {
        const searchString: string = item.matched
          .slice(item.matched.indexOf('@') + 1, item.matched.length)
          .replace('>', '');

        if (searchString === 'everyone') {
          replaceItem = '@everyone';
        } else {
          const member = this.userQuery.getUserByChatUuid(searchString);
          if (member) {
            replaceItem = `@${member.displayName}`;
          } else {
            replaceItem = item.matched;
          }
        }
      }

      output = output.replace(item.replaceString, replaceItem);
    });

    return output;
  }

  private getEmailMsgContent(msg: ChatMessage): string {
    if (msg.body.data) {
      return `Subject: ${msg.body.data.subject}\n${msg.body.data.text}`;
    }
    return '';
  }

  private handleNotifySystem(message: ChatMessage, convo: SupportedConvo) {
    const type = (<SystemMessageData>message.body?.data)?.type;
    if (!type) {
      return;
    }

    const me = this.meQuery.getMe();

    switch (type) {
      case SystemMsgType.join: {
        if (SUPPORTED_CHANNEL.indexOf(message.ct) > -1) {
          const hasMe = message.body.data?.join?.indexOf(me.userUuid) > -1;
          if (hasMe) {
            of(this.userQuery.getUserByChatUuid(message.user))
              .pipe(
                mergeMap(users =>
                  users != null
                    ? of([users])
                    : this.userService
                        .findOneByUuidAndUserTypeSequentially(message.user, 'chatId')
                        .pipe(map(x => (x ? [x] : [])))
                )
              )
              .subscribe(users => {
                const userName = users[0]?.displayName;
                const content = this.getMessageNotificationContent(message);
                if (userName) {
                  this.fireNotify(message, convo, userName, content);
                }
              });
            return;
          }
        }
        break;
      }
      case SystemMsgType.leave: {
        if (SUPPORTED_CHANNEL.indexOf(message.ct) > -1) {
          const hasMe = message.body.data?.leave?.indexOf(me.userUuid) > -1;
          if (hasMe) {
            of(this.userQuery.getUserByChatUuid(message.user))
              .pipe(
                mergeMap(users =>
                  users != null
                    ? of([users])
                    : this.userService
                        .findOneByUuidAndUserTypeSequentially(message.user, 'chatId')
                        .pipe(map(x => (x ? [x] : [])))
                )
              )
              .subscribe(users => {
                const userName = users[0]?.displayName;
                const content = this.getMessageNotificationContent(message);
                if (userName) {
                  this.fireNotify(message, convo, userName, content);
                }
              });
            return;
          }
        }
        break;
      }
      default:
        break;
    }
  }
}
